import { useState } from "react";

export const useModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const onClose: () => void = () => setIsOpen(false);
  const onOpen: () => void = () => setIsOpen(true);

  return { isOpen, onOpen, onClose, message, setMessage };
};
