import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormControl } from '@mui/material';
import { Autocomplete, TextField } from '@mui/material';

export default function UnitSelect({ units, style, onChange, required }) {
    const [selectedUnit, setSelectedUnit] = useState(null);

    // Handle the change in selected unit
    const handleChange = (event, newValue) => {
        setSelectedUnit(newValue);
        if (onChange) {
            onChange(newValue ? newValue.tuyaUnitId : null);
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const inputValue = e.target[0].value;
        const matchedUnit = units.find((unit) =>
            unit.name.toLowerCase() === inputValue.toLowerCase()
        );
        if (matchedUnit) {
            setSelectedUnit(matchedUnit);
            if (onChange) {
                onChange(matchedUnit.tuyaUnitId);

                const inputElement = document.getElementById('unit-select');
                if (inputElement) {
                    inputElement.blur(); // Blur the input to close the dropdown
                }
            }
        }
    }

    return (
        <FormControl fullWidth required={required} sx={style}>
            <Autocomplete
                id="unit-select"
                options={units}
                fullWidth
                getOptionLabel={(option) => option.name || ''}
                value={selectedUnit}
                onChange={handleChange}
                isOptionEqualToValue={(option, value) => option.tuyaUnitId === value?.tuyaUnitId}
                renderInput={(params) => (
                    <form onSubmit={handleSubmit}>
                        <TextField {...params} required={required} fullWidth label="Select Unit" id="unitText" variant="outlined" />
                    </form>
                )}
                renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.tuyaUnitId}>
                        {option.name}
                    </Box>
                )}
            />
        </FormControl>
    );
}

UnitSelect.propTypes = {
    units: PropTypes.arrayOf(
        PropTypes.shape({
            tuyaUnitId: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func,
};
