/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect, useState } from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import CardsHeader from "../../../components/Headers/CardsHeader";
import { Accordion, AccordionSummary, AccordionDetails, Typography, SelectChangeEvent, Button, FormControl, TextField } from "@mui/material";
import { BusinessCenter, Code, ExpandMore, List, PermContactCalendar, PersonAddAlt1 } from "@mui/icons-material";
import Pagination from "../components/Pagination";
import PartnerSelect from "../components/PartnerSelect";
import UsersTable from "./components/UsersTable";
import { createUser, searchForUsers } from "../../../actions/user-actions";
import { ROLES } from "../../../constants/role";
import { PartnerContext, PartnerContextType } from "../../../contexts/partner-context";
import { arrayNotNullAndHasEntries } from "../../../util/null-helper";
import Loader from "../../../components/Loader/Loader";
import { IsAdmin } from "../../../util/role-helpers";
import { useAuth } from "../../../hooks/useAuth";
import { useModal } from "../../../hooks/useModal";
import CreateUserModal from "./components/CreateUserModal";
import SnackbarComponent from "../../../components/Snackbar/Snackbar";
import { useSnack } from "../../../hooks/useSnack";

const INITIAL_PAGE_SIZE = 10;
const INITIAL_PAGE_NUMBER = 1;
const INITIAL_SORT_BY = "+FirstName";

function Users() {
    const [isLoading, setIsLoading] = useState(false);
    const [partnerId, setPartnerId] = useState(null);
    const [userName, setUserName] = React.useState('');
    const [userRole, setUserRole] = React.useState(null);
    const [firstName, setFirstName] = React.useState('');
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(INITIAL_PAGE_NUMBER);
    const [sortBy, setSortBy] = React.useState(INITIAL_SORT_BY);
    const [usersSearchResult, setUsersSearchResult] = useState(null);
    const { role } = useAuth();
    const { partners } = React.useContext<PartnerContextType>(PartnerContext);
    const userModal = useModal();
    const snack = useSnack();

    const loadUsers = useCallback(async (partnerId: string, 
        name: string, 
        username: string, 
        role: string, 
        pageSize: number, 
        pageNumber: number,
        sortBy: string) => {
            try {
                setIsLoading(true);

                const response = await searchForUsers(
                    partnerId, 
                    name,
                    username, 
                    role, 
                    pageSize,
                    pageNumber - 1,
                    sortBy);
        
                setUsersSearchResult(response.data);
                setIsLoading(false);
            }
            catch(err){
                console.log(err);
                setIsLoading(false);
            }
    }, []);

    const setDefaultPartner = useCallback(async () => {
        if (arrayNotNullAndHasEntries(partners) && partners.length === 1) {
            setPartnerId(partners[0].id);
        }
    }, [partners]); 
    
    const loadInitialUsers = useCallback(async () => {
        await loadUsers(null, null, null, null, INITIAL_PAGE_SIZE, INITIAL_PAGE_NUMBER, INITIAL_SORT_BY);
    }, [loadUsers]); 

    const onPageLoad = useCallback(async () => {
        await Promise.all([loadInitialUsers(), setDefaultPartner()]);
    }, [loadInitialUsers, setDefaultPartner]);
    
    useEffect(() => {
        onPageLoad();
    }, [onPageLoad]);


    const handlePageChange = async (e, newPage) => {
        setPageNumber(newPage);
        await loadUsers(partnerId, firstName, userName, userRole, pageSize, newPage, sortBy);
    }

    const handleUserTypeChange = async (role: string) => {
        setUserRole(role);
        setPageNumber(1);
        await loadUsers(partnerId, firstName, userName, role, pageSize, pageNumber, sortBy);
    }
    
    const handlePageSizeChange = async (e: SelectChangeEvent) => {
        const newSize =  parseInt(e.target.value);
        setPageNumber(1);
        setPageSize(newSize);
        await loadUsers(partnerId, firstName, userName, userRole, newSize, pageNumber, sortBy);
    }

    const handleClickSort = async (direction, field) => {
        const newSort = `${direction}${field}`;
        setPageNumber(1);
        setSortBy(newSort);
        await loadUsers(partnerId, firstName, userName, userRole, pageSize, pageNumber, newSort);
    }

    const handlePartnerChange = async (partnerId: string) => {
        setPartnerId(partnerId);
        setPageNumber(1);
        setUserRole(null);
        setFirstName(null);
        setUserName(null);
        await loadUsers(partnerId, firstName, userName, null, pageSize, 1, sortBy);
    };

    const handleUserNameSubmit = async (e: any) => {
        e.preventDefault();

        await loadUsers(partnerId, firstName, userName, userRole, pageSize, 1, sortBy);
    }

    const handleEmailSubmit = async (e: any) => {
        e.preventDefault();

        await loadUsers(partnerId, firstName, userName, userRole, pageSize, 1, sortBy);
    }

    const handleCreateUserError = (message: string) => {
        snack.setSeverity("error");
        snack.setMessage(message);
        snack.onOpen();
    }

    const handleCreateUser = async (user) => {

        await createUser(user);

        snack.setSeverity("success");
        snack.setMessage("User successfully created.")
        snack.onOpen();

        await loadUsers(partnerId, firstName, userName, userRole, pageSize, 1, sortBy);
    }

    return (
        <>
        <CardsHeader name="Users" parentName="Dashboard" />
        <Container className="mt--6" fluid>
            <Row style={{ marginBottom:"20px" }}>
                <Col lg={12}>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="settings-content"
                            id="settings-header"
                        >
                                <span className="h2 mb-3">Filters</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Container fluid>
                                <Row>
                                    <Col lg={3}>
                                        <PartnerSelect
                                            partners={partners}
                                            onChange={handlePartnerChange}
                                            withLoading={false}
                                            enableSelectAll={true}
                                            showPlaceHolder={true}
                                            selectedPartnerId={null}
                                            m={{}}
                                        />
                                    </Col>
                                    <Col lg={3}>
                                        <form onSubmit={(e) => handleUserNameSubmit(e)}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <TextField 
                                                    value={firstName}
                                                    onChange={(e: any) => setFirstName(e.target.value)}
                                                    fullWidth
                                                    label="Name"
                                                    placeholder="Type the user first or last name"
                                                />
                                            </FormControl>
                                        </form>
                                    </Col>
                                    <Col lg={3}>
                                        <form onSubmit={(e) => handleEmailSubmit(e)}>
                                            <FormControl sx={{ width: '100%' }}>
                                                <TextField 
                                                    value={userName}
                                                    onChange={(e: any) => setUserName(e.target.value)}
                                                    fullWidth
                                                    label="Email"
                                                    placeholder="Type the user first or last name"
                                                />
                                            </FormControl>
                                        </form>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg={9}>
                                        <Button 
                                            startIcon={<List />} 
                                            variant={(userRole === null ? "contained" : "outlined")} 
                                            onClick={() => handleUserTypeChange(null)}  
                                            size="small"
                                            sx={ { marginRight: '10px' } }
                                        >
                                            All {(usersSearchResult ? `(${usersSearchResult.allUserTypesCount})` : null)}
                                        </Button>
                                        <Button 
                                            startIcon={<BusinessCenter />} 
                                            variant={(userRole === ROLES.Admin ? "contained" : "outlined")}
                                            onClick={() => handleUserTypeChange(ROLES.Admin)} 
                                            size="small"
                                            sx={ { marginRight: '10px' } }
                                        >
                                            Admin {(usersSearchResult ? `(${usersSearchResult.adminCount})` : null)}
                                        </Button>
                                        <Button 
                                            startIcon={<Code />} 
                                            variant={(userRole === ROLES.Developer ? "contained" : "outlined")} 
                                            onClick={() => handleUserTypeChange(ROLES.Developer)} 
                                            size="small"
                                            sx={ { marginRight: '10px' } }
                                        >
                                            Developer {(usersSearchResult ? `(${usersSearchResult.developerCount})` : null)}
                                        </Button>
                                        <Button 
                                            startIcon={<PermContactCalendar />} 
                                            variant={(userRole === ROLES.Client ? "contained" : "outlined")} 
                                            onClick={() => handleUserTypeChange(ROLES.Client)} 
                                            size="small"
                                            sx={ { marginRight: '10px' } }
                                        >
                                            Client {(usersSearchResult ? `(${usersSearchResult.clientCount})` : null)}
                                        </Button>
                                        <Button 
                                            startIcon={<PermContactCalendar />} 
                                            variant={(userRole === ROLES.Client ? "contained" : "outlined")} 
                                            onClick={() => handleUserTypeChange(ROLES.DevelopmentApi)} 
                                            size="small"
                                            sx={ { marginRight: '10px' } }
                                        >
                                            Development Api {(usersSearchResult ? `(${usersSearchResult.developmentApiCount})` : null)}
                                        </Button>

                                        {IsAdmin(role) ? <Button 
                                            startIcon={<PersonAddAlt1 />} 
                                            variant={"outlined"} 
                                            size="small"
                                            onClick={() => userModal.onOpen()}
                                            sx={ { float: 'right' } }
                                        >
                                            Add User
                                        </Button> : null}
                                        <CreateUserModal
                                            open={userModal.isOpen}
                                            partners={partners}
                                            onClose={userModal.onClose}
                                            onCreateUserError={handleCreateUserError}
                                            onCreateUser={handleCreateUser}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col lg={12} className="d-flex justify-content-center" style={{ position: 'absolute'}}>
                                        {isLoading === true ? <Loader /> : null}
                                    </Col>
                                    <Col lg={12}>
                                        <div style={{  marginTop: 5 }}>
                                            <Typography>
                                                ({usersSearchResult ? usersSearchResult.totalCount : 0}) records found.
                                            </Typography>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </AccordionDetails>
                    </Accordion>
                </Col>
            </Row>
            <Row>
                <Col xl={12} className="d-flex justify-content-center mb-3">
                    {usersSearchResult ? <Pagination 
                        totalPages={usersSearchResult.totalPages}
                        onChange={handlePageChange}
                        page={pageNumber}
                    /> : null}
                </Col>
                <Col>
                    <UsersTable 
                        users={usersSearchResult ? usersSearchResult.items : []}
                        handleClickSort={handleClickSort}
                        handlePageSizeChange={handlePageSizeChange}
                        pageSize={pageSize}
                        sortBy={sortBy}
                        isLoading={isLoading}
                        partners={partners}
                    />

                </Col>
                <Col xl={12} className="d-flex justify-content-center mb-3">
                    {usersSearchResult ? <Pagination 
                        totalPages={usersSearchResult.totalPages}
                        onChange={handlePageChange}
                        page={pageNumber}
                    /> : null}
                </Col>
            </Row>
            <Row>
                <Col lg={12} className="d-flex justify-content-center" style={{ position: 'absolute'}}>
                    {isLoading === true ? <Loader /> : null}
                </Col>
            </Row>
        </Container>
        <SnackbarComponent
            isOpen={snack.isOpen}
            severity={snack.severity}
            onClose={snack.onClose}
            message={snack.message}
        />
        </>
    );
}

export default Users;
