import { Alert, Snackbar } from "@mui/material";

export default function SnackbarComponent({ isOpen, onClose, severity, message }){
    return <Snackbar
        open={isOpen}
        autoHideDuration={6000}
        onClose={onClose}
    >
        <Alert
            onClose={onClose}
            severity={severity}
            variant="filled"
            sx={{ width: '100%' }}
        >
            {message}
        </Alert>
    </Snackbar>
}