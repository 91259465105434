import { isNotNullOrEmpty } from "./null-helper";

export const API_URL = process.env.REACT_APP_CORE_API_URL ? process.env.REACT_APP_CORE_API_URL : '';
export const FUNCTIONS_KEY = process.env.REACT_APP_CORE_API_FUNCTION_KEY;

export const DEFAULT_HTTP_HEADERS: any = FUNCTIONS_KEY ? {
    headers: {
        'x-functions-key': FUNCTIONS_KEY,
        'x-access-token': ''
    }
} : null;

export const httpHeadersWithAuthorization = (token: string) => {
    DEFAULT_HTTP_HEADERS.headers['x-access-token'] = token;

    return DEFAULT_HTTP_HEADERS;
}

export const endpoint = (uri: string) => {
    return process.env.REACT_APP_CORE_API_URL 
        ? `${process.env.REACT_APP_CORE_API_URL}/${uri}`
        : `${window.location.origin}/${uri}`;
}

export const getQueryStringParams = (params: any) => {
    let queryStringParams = "";

    Object.keys(params).map(function(key) {
        const value = params[key];
        if(isNotNullOrEmpty(value)) {
            queryStringParams += `${key}=${encodeURIComponent(params[key])}&`
        }
        return key;
    });

    return queryStringParams !== "" 
        ? queryStringParams.slice(0, -1) 
        : queryStringParams;
}