import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
    FormControl,
    TextField,
    Box,
    Checkbox,
    ListItemText,
    MenuItem,
    Autocomplete
} from '@mui/material';
import { arrayNotNullAndHasEntries } from '../../../util/null-helper';

export default function UnitMultipleSelect({ partnerId, units, style, onChange, required, disabled }) {
    const [selectedUnits, setSelectedUnits] = useState([]);
    const [selectedPartnerId, setPartnerId] = useState('');

    // Handle the change in selected units
    const handleChange = (event, newValue) => {
        const selectedIds = newValue.map((unit) => unit.tuyaUnitId);
        setSelectedUnits(selectedIds);
        if (onChange) {
            onChange(newValue.map((unit) => {
                return {
                    internalUnitId: unit.tuyaUnitId,
                    unitName: unit.name
                }
            }));
        }
    };

    const getFieldText = (text, selectedUnits) => {
        return arrayNotNullAndHasEntries(selectedUnits) 
            ? `${text} (${selectedUnits.length})`
            : text;
    }

    useEffect(() => {
        if(selectedPartnerId !== partnerId) {
            setPartnerId(partnerId);
            setSelectedUnits([]);
            onChange([]);
        }
    }, [selectedPartnerId, partnerId, setSelectedUnits])

    return (
        <FormControl fullWidth required={required} sx={style}>
            <Autocomplete
                multiple
                disabled={disabled}
                id="unit-multiple-select"
                options={units}
                getOptionLabel={(option) => option.name}
                value={units.filter((unit) => selectedUnits.includes(unit.tuyaUnitId))}
                onChange={handleChange}
                isOptionEqualToValue={(option, value) => option.tuyaUnitId === value.tuyaUnitId}
                disableCloseOnSelect
                renderInput={(params) => (
                    <TextField 
                        label={getFieldText("Select Units", selectedUnits)} 
                        placeholder={getFieldText("Search and select units", selectedUnits)} 
                        {...params} 
                    />
                )}
                renderOption={(props, option) => (
                    <Box component="li" {...props} key={option.tuyaUnitId}>
                        <Checkbox checked={selectedUnits.includes(option.tuyaUnitId)} />
                        <ListItemText primary={option.name} />
                    </Box>
                )}
            />
        </FormControl>
    );
}

UnitMultipleSelect.propTypes = {
    units: PropTypes.arrayOf(
        PropTypes.shape({
            tuyaUnitId: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
        })
    ).isRequired,
    onChange: PropTypes.func,
    style: PropTypes.object,
    required: PropTypes.bool,
};
