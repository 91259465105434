
import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";

type DeleteConfirmationDialogProps = {
    isOpen: boolean;
    message: string,
    accessToDeleteId: string,
    onClose: () => void;
    onConfirmDelete: (reservation: any) => void;
};

export default function DeleteConfirmationdDialog({
    isOpen,
    message,
    onClose,
    accessToDeleteId,
    onConfirmDelete,
}: DeleteConfirmationDialogProps) {
    
    const handleConfirmDelete = () => {
        onConfirmDelete(accessToDeleteId);
    };

    return (
        <Dialog open={isOpen} onClose={onClose} aria-labelledby="delete-password-dialog-title">
            <DialogTitle id="delete-password-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
                <Typography>
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleConfirmDelete} color="primary" variant="contained">
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}
