import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, IconButton, InputAdornment, Modal, TextField, Typography } from "@mui/material"
import { Col, Container, Row } from "reactstrap";
import { EasyHubPrimaryColor } from "../../../../variables/colors";
import DateField from "../../../../components/Date/DateField";
import { arrayNotNullAndHasEntries, arrayNullOrHasNoEntries, isNotNull, isNotNullOrEmpty, isNullOrEmpty } from "../../../../util/null-helper";
import moment from "moment-timezone";
import { CreateAccessCommandType, CreateAccessCommandUnitType } from "../../../../actions/accesses-actions";
import { MODAL_STYLE } from "../../../../constants/modal";
import UnitSelect from "../../components/UnitSelect";
import PartnerSelect from "../../components/PartnerSelect";
import Loader from "../../../../components/Loader/Loader";
import { PickerValidDate } from "@mui/x-date-pickers";
import { Refresh } from "@mui/icons-material";
import { randomSixDigitsNumber } from "../../../../util/numbers-helper";
import UnitMultipleSelect from "../../components/UnitMultipleSelect";

type CreateAccessModalProps = {
    open: boolean,
    partners: Array<any>,
    units: Array<any>,
    isAdmin: boolean,
    onClose: () => void,
    onCreateAccessCode: (access: CreateAccessCommandType) => void,
    onCreateAccessCodeError: (message: string) => void,
    onPartnerChange: (id: string) => void
}

export default function CreateAccessModal({ 
    open, 
    partners, 
    units,
    isAdmin,
    onClose, 
    onCreateAccessCode, 
    onCreateAccessCodeError,
    onPartnerChange,
}: CreateAccessModalProps){

    const [partnerId, setPartnerId] = useState<string>(null);
    const [code, setCode] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [startDateEST, setStartDateEST] = useState("");
    const [endDate, setEndDate] = useState(null);
    const [endDateEST, setEndDateEST] = useState("");
    const [selectedUnits, setSelectedUnits] = useState<CreateAccessCommandUnitType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [minEndDate, setMinEndDate] = useState(null);
    const minStartDate = moment();
    

    const clearSelection = () => {
        setPartnerId(null);
        setSelectedUnits([]);
        setCode("");
        setFirstName("");
        setLastName("");
        setEmail("");
        setStartDate(null);
        setEndDate(null);
        setEndDateEST(null);
        setStartDateEST(null);
        setMinEndDate(minStartDate);
    }
    const handleClose = () => {
        onClose();
        clearSelection();
    }

    const handlePartnerChange = (id) => {
        setPartnerId(id);
        onPartnerChange(id);
        setSelectedUnits([]);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if(arrayNullOrHasNoEntries(selectedUnits)){
            onCreateAccessCodeError("Please select a unit.");
            return;
        }

        if (isNullOrEmpty(startDate) || isNullOrEmpty(endDate)) {
            onCreateAccessCodeError("Please select a start and end date.");
            return;
        }

        if (code.length < 6) {
            onCreateAccessCodeError("The Access Code must have at least 6 digits.");
            return;
        }

        if (code.length > 6) {
            onCreateAccessCodeError("The Access Code must have a maximum of 6 digits.");
            return;
        }

        if(startDate > endDate){
            onCreateAccessCodeError("The start date can't be greater than the end date.");
            return;
        }

        try {
            setIsLoading(true);
            await onCreateAccessCode({
                partnerId,
                code,
                firstName,
                lastName,
                email,
                startDateTime: startDateEST,
                endDateTime: endDateEST,
                units: selectedUnits
            });
            clearSelection();
        } catch(err){
            const msg = isNotNullOrEmpty(err.response) && isNotNullOrEmpty(err.response.data) 
                ? err.response.data 
                : "Unable to create the temporary access. Try again later or contact support";

            onCreateAccessCodeError(msg);
        }
        finally {
            setIsLoading(false);
        }
    }
    
    const getPartnerName = (partnerId: string) => {
        let result = "-";

        if(arrayNotNullAndHasEntries(partners) && isNotNullOrEmpty(partnerId)) {
            const filtered = partners.filter(i => i.id === partnerId);
            result = arrayNotNullAndHasEntries(filtered) ? filtered[0].name : '-';
        }

        return result;
    }

    const handleDateChange = async (val, setDateTime, setDateTimeZone) => {
        setDateTime(val);
        const dateTimeZone = moment.tz(val.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', 'America/New_York').format();
        setDateTimeZone(dateTimeZone);
    }

    const handleStartDateChange = async (val) => {
        setStartDate(val);
        const dateTimeZone = moment.tz(val.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', 'America/New_York').format();
        setStartDateEST(dateTimeZone);
        setMinEndDate(val);
    }
    return open === true ? <Modal
        open={open}
        onClose={() => {}}
        aria-labelledby="modal-create-access-title"
        aria-describedby="modal-create-access-description"
        >
        <Box sx={MODAL_STYLE}>
            <form onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col lg={12} style={{ color: EasyHubPrimaryColor, marginLeft: '10px' }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Create Visitor Password
                            </Typography>
                        </Col>
                        <Col lg={6} style={{ marginTop: "30px"}}>
                            {isAdmin === true ? <PartnerSelect
                                    partners={partners}
                                    onChange={(id) => handlePartnerChange(id)}
                                    withLoading={false}
                                    enableSelectAll={false}
                                    showPlaceHolder={true}
                                    selectedPartnerId={partnerId}
                                    required={true}
                                    m={{}}
                                /> : <FormControl fullWidth>
                                <TextField
                                    id="update_reservation_partner"
                                    label="Client"
                                    value={getPartnerName(partnerId)}
                                    required
                                    disabled
                                />
                            </FormControl>}
                        </Col> 
                        <Col lg={6}>
                            <UnitMultipleSelect
                                units={units}
                                onChange={setSelectedUnits}
                                style={{ marginTop: '30px' }}
                                required={true}
                                partnerId={partnerId}
                                disabled={isNullOrEmpty(partnerId)}
                            />
                        </Col>
                        <Col lg={6} style={{ marginTop: "30px"}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="update_reservation_first_name"
                                    label="First name"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    required
                                />
                            </FormControl>
                        </Col> 
                        <Col lg={6} style={{ marginTop: '30px'}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="update_reservation_last_name"
                                    label="Last Name"
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lastName}
                                    required
                                />
                            </FormControl>
                        </Col>
                        <Col lg={6} style={{ marginTop: '30px'}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="update_reservation_code"
                                    label="Code"
                                    onChange={(e) => { if(e.target.value.length <= 6) { setCode(e.target.value); } }}
                                    value={code}
                                    type="number"
                                    required
                                    slotProps={{
                                        input: {
                                          endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setCode(randomSixDigitsNumber().toString())}>
                                                    <Refresh />
                                                </IconButton>
                                            </InputAdornment>
                                          ),
                                        },
                                      }}
                                />
                            </FormControl>
                        </Col>
                        <Col lg={6} style={{ marginTop: "30px"}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="update_reservation_email"
                                    label="E-mail"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                />
                            </FormControl>
                        </Col> 
                        <Col lg={6} style={{ marginTop: '30px'}}>
                            <FormControl required fullWidth>
                                <DateField
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(newValue) => handleStartDateChange(newValue)}
                                    minDate={minStartDate}
                                />
                            </FormControl>
                        </Col>
                        <Col lg={6} style={{ marginTop: "30px"}}>
                            <FormControl required fullWidth>
                                <DateField
                                    label="End Date"
                                    value={endDate}
                                    onChange={(newValue) => handleDateChange(newValue, setEndDate, setEndDateEST)}
                                    minDate={minEndDate}
                                />
                            </FormControl>
                        </Col> 
                        <Col lg={12} style={{ marginTop: "30px"}}>
                        <Box className="d-flex" sx={{ float: 'right'}}>
                            {isLoading === true ? <Box sx={{ marginRight: '15px'}}>
                                    <Loader /> 
                            </Box>: null}
                            <Button variant="outlined" disabled={isLoading} onClick={handleClose} style={{ marginRight: "10px"}}>
                                Cancel
                            </Button>
                            <Button type="submit" disabled={isLoading} variant="contained">
                                Create
                            </Button>
                        </Box>
                        </Col>
                    </Row>
                </Container>
            </form>
           
        </Box>
    </Modal> : <></>;
}