/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useCallback, useEffect, useState } from "react";
// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import CardsHeader from "../../../components/Headers/CardsHeader";
import { Accordion, AccordionSummary, AccordionDetails, Typography, Select, MenuItem, FormControl, InputLabel, TextField, SelectChangeEvent, Button } from "@mui/material";
import { Create, ExpandMore, LockOutlined, Refresh } from "@mui/icons-material";
import { useAuth } from "../../../hooks/useAuth";
import { searchForReservations } from "../../../actions/reservation-actions";
import ReservationsTable from "../components/tables/ReservationsTable";
import Pagination from "../components/Pagination";
import PartnerSelect from "../components/PartnerSelect";
import { searchForUnits } from "../../../actions/unit-actions";
import UnitSelect from "../components/UnitSelect";
import Loader from "../../../components/Loader/Loader";
import DateField from "../../../components/Date/DateField";
import moment from "moment-timezone";
import { arrayNotNullAndHasEntries, isNotNullOrEmpty } from "../../../util/null-helper";
import { IsAdmin } from "../../../util/role-helpers";
import { convertTo13DigitsTimeStamp } from "../../../util/time-helpers";
import { PartnerContext, PartnerContextType } from "../../../contexts/partner-context";
import AccessTable from "./components/AccessTable";
import { searchForAccesses } from "../../../actions/accesses-actions";
import { useModal } from "../../../hooks/useModal";

const INITIAL_PAGE_SIZE = 10;
const INITIAL_PAGE_NUMBER = 1;

function Access() {

    const [loadingAccesses, setLoadingAccesses] = useState(false);
    const [accessesSearchResult, setAccessesSearchResult] = useState(null);
    const [unitSearchResult, setUnitSearchResult] = useState(null);
    const [partnerId, setPartnerId] = useState(null);
    const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
    const [pageNumber, setPageNumber] = useState(INITIAL_PAGE_NUMBER);
    const [sortBy, setSortBy] = React.useState('+StartDateTimeStamp');
    const [tuyaUnitId, setTuyaUnitId] = useState(null);
    const [accessStatus, setAccessStatus] = useState('All');
    const [accessPersonName, setAcessPersonName] = useState(null);
    const [email, setEmail] = useState(null);

    const [startDateFrom, setStartDateFrom] = useState(null);
    const [startDateFromEST, setStartDateFromEST] = useState(null);
    const [startDateUntil, setStartDateUntil] = useState(null);
    const [startDateUntilEST, setStartDateUntilEST] = useState(null);

    const [endDateFrom, setEndDateFrom] = useState(null);
    const [endDateFromEST, setEndDateFromEST] = useState(convertTo13DigitsTimeStamp(moment.utc().unix()));
    const [endDateUntil, setEndtDateUntil] = useState(null);
    const [endDateUntilEST, setEndDateUntilEST] = useState(null);

    const { partners } = React.useContext<PartnerContextType>(PartnerContext);
    
    const createAccessModal = useModal();

    var { role } = useAuth();

    const loadAccesses = useCallback(async (partnerId: string, 
        internalUnitId: string,
        email: string, 
        status: string,
        name: string, 
        startDateFrom: string, 
        startDateUntil: string, 
        endDateFrom: string, 
        endDateUntil: string, 
        pageSize: number, 
        pageNumber: number, 
        sortBy: string) => {
            try {
                setLoadingAccesses(true);

                const theStatus = status === 'All' ? null : status;
                
                const response = await searchForAccesses(
                    partnerId,
                    internalUnitId,
                    theStatus,
                    name,
                    email,
                    startDateFrom,
                    startDateUntil,
                    endDateFrom,
                    endDateUntil,
                    pageSize, 
                    pageNumber - 1, 
                    sortBy);
        
                setAccessesSearchResult(response.data);
                setLoadingAccesses(false);
            }
            catch(err){
                console.log(err);
                setLoadingAccesses(false);
            }
    }, []);

    const setDefaultPartner = useCallback((partners) => {
        if (arrayNotNullAndHasEntries(partners) && partners.length === 1) {
            setPartnerId(partners[0].id);
        }
    }, []); 

    const loadUnits = useCallback(async (partnerId) => {
        const response = await searchForUnits(partnerId, null, 300, 0, '+Name');
        setUnitSearchResult(response.data);
    }, []); 

    const convertToTimeStamp = (val) => {
        return isNotNullOrEmpty(val) ? moment(val).unix() : null;;
    }

    const handlePageChange = async (e, newPage) => {
        setPageNumber(newPage);
        await loadAccesses(partnerId, tuyaUnitId, email, accessStatus, accessPersonName, startDateFrom, startDateUntil, endDateFrom, endDateUntil, pageSize, newPage, sortBy);
    }
    
    const handlePartnerChange = async (partnerId: string) => {
        setPartnerId(partnerId);
        setPageNumber(1);
        await Promise.all([
            loadAccesses(partnerId, tuyaUnitId, email, accessStatus, accessPersonName, startDateFrom, startDateUntil, endDateFrom, endDateUntil, pageSize, pageNumber, sortBy),
            loadUnits(partnerId)
        ]);
      };

    const handleUnitChange = async (tuyaUnitId) => {
        setPageNumber(1);
        setTuyaUnitId(tuyaUnitId);
        await loadAccesses(partnerId, tuyaUnitId, email, accessStatus, accessPersonName, startDateFrom, startDateUntil, endDateFrom, endDateUntil, pageSize, pageNumber, sortBy);

    }

    const handlePageSizeChange = async (e: SelectChangeEvent) => {
        const newSize =  parseInt(e.target.value);
        setPageNumber(1);
        setPageSize(newSize);
        await loadAccesses(partnerId, tuyaUnitId, email, accessStatus, accessPersonName, startDateFrom, startDateUntil, endDateFrom, endDateUntil, newSize, pageNumber, sortBy);
    }

    const handleClickSort = async (direction, field) => {
        const newSort = `${direction}${field}`;
        setPageNumber(1);
        setSortBy(newSort);
        await loadAccesses(partnerId, tuyaUnitId, email, accessStatus, accessPersonName, startDateFrom, startDateUntil, endDateFrom, endDateUntil, pageSize, pageNumber, newSort);
    }
    
    const handleStartDateFromChange = async (val) => {
        setStartDateFrom(val);
        const dateTimeZone = moment.tz(val.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', 'America/New_York').format();
        setStartDateFromEST(dateTimeZone);
        setPageNumber(1);

        await loadAccesses(partnerId, tuyaUnitId, email, accessStatus, accessPersonName, dateTimeZone, startDateUntil, endDateFrom, endDateUntil, pageSize, pageNumber, sortBy);
    }

    const handleStartDateUntilChange = async (val) => {
        setStartDateUntil(val);
        const dateTimeZone = moment.tz(val.format('YYYY-MM-DD HH:mm'), 'YYYY-MM-DD HH:mm', 'America/New_York').format();
        setStartDateUntilEST(dateTimeZone);
        setPageNumber(1);

        await loadAccesses(partnerId, tuyaUnitId, email, accessStatus, accessPersonName, startDateFrom, dateTimeZone, endDateFrom, endDateUntil, pageSize, pageNumber, sortBy);
    }

    const handleStatusChange = async (e) => {
        const status = e.target.value;
        setAccessStatus(status);
        setPageNumber(1);

        await loadAccesses(partnerId, tuyaUnitId, email, status, accessPersonName, startDateFrom, startDateUntil, endDateFrom, endDateUntil, pageSize, pageNumber, sortBy);
    }

    const handleNameChange = async (e) => {
        e.preventDefault();
        setPageNumber(1);

        await loadAccesses(partnerId, tuyaUnitId, email, accessStatus, accessPersonName, startDateFrom, startDateUntil, endDateFrom, endDateUntil, pageSize, pageNumber, sortBy);
    }

    const handleEmailChange = async (e) => {
        e.preventDefault();
        setPageNumber(1);

        await loadAccesses(partnerId, tuyaUnitId, email, accessStatus, accessPersonName, startDateFrom, startDateUntil, endDateFrom, endDateUntil, pageSize, pageNumber, sortBy);
    }

    const handleRefresh = async () => {
        await loadAccesses(partnerId, tuyaUnitId, email, accessStatus, accessPersonName, startDateFrom, startDateUntil, endDateFrom, endDateUntil, pageSize, pageNumber, sortBy);
    }

    const loadInitialAccesses = useCallback(async () => {
        //const utcTmeStampNow = convertTo13DigitsTimeStamp(moment.utc().startOf('day').unix());
        const utcTmeStampNow = null;
        await loadAccesses(null, null, null, null, null, utcTmeStampNow, null, null, null, INITIAL_PAGE_SIZE, INITIAL_PAGE_NUMBER, "+ArrivalTimeStamp");
    }, [loadAccesses]); 

    const onPageLoad = useCallback(async () => {
        await Promise.all([loadInitialAccesses()]);
        await loadUnits(null);
    }, [loadInitialAccesses, loadUnits]);
    
    useEffect(() => {
        onPageLoad();
    }, [onPageLoad]);

    useEffect(() => {
        setDefaultPartner(partners);
    }, [partners, setDefaultPartner]);

    return (
        <>
        <CardsHeader name="Reservations" parentName="Dashboard" />
        <Container className="mt--6" fluid>
            <Row style={{ marginBottom:"20px" }}>
                <Col>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="settings-content"
                            id="settings-header"
                        >
                                <span className="h2 mb-3">Filters</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="mb-5 mr-5">
                                {IsAdmin(role) ? <Row className="pr-0 pr-sm-0 pr-md-3 pr-lg-4 pr-xl-5">
                                    <Col lg={3}>
                                        <PartnerSelect
                                            partners={partners}
                                            onChange={handlePartnerChange}
                                            withLoading={false}
                                            enableSelectAll={true}
                                            showPlaceHolder={true}
                                            selectedPartnerId={null}
                                            m={{}}
                                        />
                                    </Col> 
                                    <Col lg={3}>
                                        <UnitSelect
                                            units={unitSearchResult ? unitSearchResult.items : []}
                                            onChange={handleUnitChange}
                                            style={{}}
                                            required={false}
                                        />
                                    </Col>
                                </Row> :  <Row>
                                    <Col lg={3}>
                                        <UnitSelect
                                            units={unitSearchResult ? unitSearchResult.items : []}
                                            onChange={handleUnitChange}
                                            required={false}
                                            style={{}}
                                        />
                                    </Col>
                                </Row>}
                                <Row className="pr-0 pr-sm-0 pr-md-3 pr-lg-4 pr-xl-5 pt-3">
                                    <Col lg={3}>
                                        <form onSubmit={(e) => handleNameChange(e)}> 
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="access_name"
                                                    label="Name"
                                                    onChange={(e) => setAcessPersonName(e.target.value)}
                                                    value={accessPersonName}
                                                />
                                            </FormControl>
                                        </form>
                                    </Col>
                                    <Col lg={3}>
                                        <form onSubmit={(e) => handleEmailChange(e)}> 
                                            <FormControl fullWidth>
                                                <TextField
                                                    id="email"
                                                    label="E-mail"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    value={email}
                                                />
                                            </FormControl>
                                        </form>
                                    </Col>
                                    <Col lg={3}>
                                        <FormControl required fullWidth>
                                            <DateField
                                                label="Start Date From"
                                                value={startDateFrom}
                                                onChange={(newValue) => handleStartDateFromChange(newValue)}
                                            />
                                        </FormControl>
                                    </Col>

                                    <Col lg={3}>
                                        <FormControl required fullWidth>
                                            <DateField
                                                label="Start Date Until"
                                                value={startDateUntil}
                                                onChange={(newValue) => handleStartDateUntilChange(newValue)}
                                            />
                                        </FormControl>
                                    </Col>
                                </Row>
                                <Row className="pr-0 pr-sm-0 pr-md-3 pr-lg-4 pr-xl-5 pt-3">
                                    <Col lg={12}>
                                        <Button 
                                            startIcon={<Refresh />} 
                                            variant={"outlined"} 
                                            onClick={() => handleRefresh()} 
                                            size="small"
                                            sx={ { float: 'right' } }>
                                            Refresh
                                        </Button>
                                        <Button 
                                            startIcon={<LockOutlined />} 
                                            variant={"outlined"} 
                                            onClick={() => createAccessModal.onOpen()} 
                                            size="small"
                                            sx={ { float: 'right', marginRight: '10px' } }>
                                            Create
                                        </Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12} className="d-flex justify-content-center" style={{ position: 'absolute'}}>
                                        {loadingAccesses === true ? <Loader /> : null}
                                    </Col>
                                    <Col lg={12}>
                                        <div style={{ marginLeft: 20, marginTop: 5 }}>
                                            <Typography>
                                                ({accessesSearchResult ? accessesSearchResult.totalCount : 0}) records found.
                                            </Typography>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </Col>
            </Row>
            <Row>
                <Col xl={12} className="d-flex justify-content-center mb-3">
                    {accessesSearchResult ? <Pagination 
                        totalPages={accessesSearchResult.totalPages}
                        onChange={handlePageChange}
                        page={pageNumber}
                    /> : null}
                </Col>
                <Col>
                    <AccessTable 
                        accesses={accessesSearchResult ? accessesSearchResult.items : []}
                        partners={partners}
                        units={unitSearchResult ? unitSearchResult.items: []}
                        pageSize={pageSize}
                        role={role}
                        sortBy={sortBy}
                        isLoading={loadingAccesses}
                        createAccessModal={createAccessModal}
                        isAdmin={IsAdmin(role)}
                        handleRefreshAccesses={handleRefresh}
                        handleClickSort={handleClickSort}
                        handlePageSizeChange={handlePageSizeChange}
                        handlePartnerChange={handlePartnerChange}
                    />

                </Col>
                <Col xl={12} className="d-flex justify-content-center mb-3">
                    {accessesSearchResult ? <Pagination 
                        totalPages={accessesSearchResult.totalPages}
                        onChange={handlePageChange}
                        page={pageNumber}
                    /> : null}
                </Col>
            </Row>
            <Row>
                <Col lg={12} className="d-flex justify-content-center" style={{ position: 'absolute'}}>
                    {loadingAccesses === true ? <Loader /> : null}
                </Col>
            </Row>
        </Container>
        </>
    );
}

export default Access;
