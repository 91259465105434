import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker, PickerValidDate } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment-timezone';

type DateFieldProps = {
  label: string, 
  value: any, 
  onChange: (e: any) => void;
  minDate?: any
}

export default function DateField({ label, value, onChange, minDate }: DateFieldProps){
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          label={label}
          value={value}
          format='DD/MMM/YYYY HH:mm'
          onChange={onChange}
          minDate={minDate}
        />
    </LocalizationProvider>
  );
}