import React, { useState } from "react";
import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { Col, Container, Row } from "reactstrap";
import { EasyHubPrimaryColor } from "../../../../variables/colors";
import { RemoveRedEye, VisibilityOff } from "@mui/icons-material";
import PartnerSelect from "../../components/PartnerSelect";
import { ROLES } from "../../../../constants/role";
import { red } from "@mui/material/colors";
import { MODAL_STYLE } from "../../../../constants/modal";

type CreateUserModalProps = {
    open: boolean,
    partners: Array<any>,
    onClose: () => void,
    onCreateUser: (user: any) => void,
    onCreateUserError: (message: string) => void
}

export default function CrateUserModal({ open, partners, onClose, onCreateUser, onCreateUserError }: CreateUserModalProps){

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [partnerId, setPartnerId] = useState<string>(null);
    const [userType, setUserType] = useState(ROLES.Admin);

    const handleClose = () => {
        setFirstName("");
        setLastName("");
        setEmail("");
        setPassword("");
        setPasswordVisible(false);
        setUserType(ROLES.Admin);
        setPartnerId(null);
        onClose();
    }

    const handleChangeUserType = (type) => {
        setUserType(type);
        setPartnerId(null);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if(password.length < 6) {
            onCreateUserError("Password must contain at least 6 digits.");
            return;
        }

        try {
            await onCreateUser({
                username: email,
                passwordHash: password,
                role: userType,
                firstName: firstName,
                lastName: lastName,
                partnerId: partnerId
            });
            handleClose();
        } catch(err){
            onCreateUserError(err.response.data);
        }
    }

    return open === true ? <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-create-user-title"
        aria-describedby="modal-create-user-description"
        >
        <Box sx={MODAL_STYLE}>
            <form onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col lg={12} style={{ color: EasyHubPrimaryColor, marginLeft: '10px' }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Create User
                            </Typography>
                        </Col>
                        <Col lg={6} style={{ marginTop: '30px'}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="create_user_first_name"
                                    label="First Name"
                                    onChange={(e) => setFirstName(e.target.value)}
                                    value={firstName}
                                    required
                                    autoFocus
                                />
                            </FormControl>
                        </Col>
                        <Col lg={6} style={{ marginTop: '30px'}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="create_user_last_name"
                                    label="Last Name"
                                    onChange={(e) => setLastName(e.target.value)}
                                    value={lastName}
                                    required
                                />
                            </FormControl>
                        </Col>
                        <Col lg={6} style={{ marginTop: '20px'}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="create_user_email"
                                    label="E-Mail"
                                    type="email"
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                    required
                                />
                            </FormControl>
                        </Col>
                        <Col lg={6} style={{ marginTop: '20px'}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="create_user_password"
                                    label="Password"
                                    type={passwordVisible === true ? "text" : "password"}
                                    onChange={(e) => setPassword(e.target.value)}
                                    value={password}
                                    required
                                    slotProps={{
                                        input: {
                                            endAdornment: (
                                                <InputAdornment position="start">
                                                    <IconButton onClick={() => setPasswordVisible(!passwordVisible)}>
                                                        {passwordVisible === false ? <RemoveRedEye /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }
                                    }}
                                />
                            </FormControl>
                        </Col>
                        <Col lg={6} style={{ marginTop: '20px'}}>
                            <FormControl fullWidth>
                                <InputLabel id="role-select-label">Role</InputLabel>
                                <Select
                                    labelId="role-select-label"
                                    id="role-select"
                                    value={userType}
                                    required
                                    onChange={(e) => handleChangeUserType(e.target.value)}
                                    input={
                                        <OutlinedInput
                                            label={"Select"}
                                        />
                                    }
                                >
                                    <MenuItem key="Admin" value={ROLES.Admin}>
                                        Admin
                                    </MenuItem>
                                    <MenuItem key="Developer" value={ROLES.Developer}>
                                        Developer
                                    </MenuItem>
                                    <MenuItem key="Client" value={ROLES.Client}>
                                        Client
                                    </MenuItem>
                                    <MenuItem key="Development Api" value={ROLES.DevelopmentApi}>
                                        Development Api
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Col>
                        {userType === ROLES.Client ? <Col lg={6} style={{ marginTop: "20px"}}>
                            <PartnerSelect
                                partners={partners}
                                onChange={(partnerId: string) => setPartnerId(partnerId)}
                                onClearSelection={() => {}}
                                withLoading={false}
                                enableSelectAll={false}
                                showPlaceHolder={false}
                                selectedPartnerId={null}
                                required={true}
                                m={{}}
                            />
                        </Col> : null}
                        {userType !== ROLES.Client ? <Col lg={12} style={{ color: red[500], marginTop: '10px' }}>
                            <Typography>
                                Warning! Admin and Developer roles have access to all clients
                            </Typography>
                        </Col>: null}
                        <Col lg={12} style={{ textAlign: "right", marginTop: "30px"}}>
                            <Button variant="outlined" onClick={handleClose} style={{ marginRight: "10px"}}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained">
                                Create
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </form>
           
        </Box>
    </Modal> : <></>;
}