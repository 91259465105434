import { useEffect, useState } from 'react';
import { Box, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import Loader from '../../../components/Loader/Loader';
import './style.css';
import { arrayNotNullAndHasEntries, isNotNullOrEmpty, isNullOrEmpty } from '../../../util/null-helper';

type PartnerSelectProps = {
    partners: Array<any>, 
    onChange: (partnerId: string) => void, 
    onClearSelection?: () => void,
    selectedPartnerId: string, 
    withLoading: boolean, 
    enableSelectAll: boolean, 
    showPlaceHolder: boolean,
    m: any,
    required?: boolean,
    disabled?: boolean
}

export default function PartnerSelect({ 
    partners, 
    onChange, 
    onClearSelection, 
    selectedPartnerId, 
    withLoading, 
    enableSelectAll, 
    showPlaceHolder, 
    m,
    required,
    disabled }: PartnerSelectProps) {
    const [partnerId, setPartnerId] = useState(required === true 
        ? selectedPartnerId 
        : isNullOrEmpty(selectedPartnerId) && showPlaceHolder === true && enableSelectAll === false 
            ? 'select' 
            : 'ALL');

    const [isLoading, setIsLoading] = useState(false);

    const handleChange = async (event) => {
        const selectedId = event.target.value;

        if(selectedId === "select"){
            if(onClearSelection){
                onClearSelection();
            }
            setPartnerId('select');
            return;
        }

        setIsLoading(true);
        try {

            setPartnerId(selectedId);
            if (onChange) {
                await onChange(selectedId === 'ALL' ? null : selectedId);
            }
        } catch (err) {
            console.error(err);
        }
        setIsLoading(false);
    };

    const displayComponents = (isLoading, shouldDisplayLoading) => {
        return isLoading === false || shouldDisplayLoading === false;
    }

    useEffect(() => {
        if(isNullOrEmpty(selectedPartnerId)){
            setPartnerId(required === true 
                ? null 
                : showPlaceHolder === true && enableSelectAll === false 
                    ? 'select' 
                    : 'ALL');
        } else {
            setPartnerId(selectedPartnerId);
        }
    }, [selectedPartnerId, showPlaceHolder, required, enableSelectAll]);

    return (
        <FormControl sx={{ m: isNotNullOrEmpty(m) ? m : 2, width: '100%' }}>
            {showPlaceHolder === true ? <InputLabel id="partner-select-label">Client</InputLabel> : null}
            {displayComponents(isLoading, withLoading) === true ? (
                <Select
                    labelId="partner-select-label"
                    id="partner-select"
                    value={partnerId} // Ensure 'ALL' is correctly handled
                    onChange={handleChange}
                    required={required}
                    disabled={disabled}
                    input={
                        <OutlinedInput
                          label={"Select"}
                        />
                      }
                >
                    {enableSelectAll === false ? null : <MenuItem key="All" value="ALL">
                        All Clients
                    </MenuItem>}

                    {showPlaceHolder === true && enableSelectAll === false ? <MenuItem key="select" value="select">
                        Select a Client
                    </MenuItem> : null}

                    {arrayNotNullAndHasEntries(partners) && partners.map((partner: any) => (
                        <MenuItem key={partner.id} value={partner.id}>
                            {partner.name}
                        </MenuItem>
                    ))}
                </Select>
            ) : (
                <Box>
                    <div style={{ float: 'right', marginRight: '10px' }}>
                        <Loader />
                    </div>
                </Box>
            )}
        </FormControl>
    );
}