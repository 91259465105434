import axios from "axios"
import { createClient } from "../util/axios-helpers";
import { getQueryStringParams } from "../util/http-helpers";
import { endpoint } from "../util/http-helpers";

export const searchForAccesses = (
    partnerId: string, 
    internalUnitId: string,
    status?: string, 
    name?: string, 
    email?: string,
    startDateFrom?: string, 
    startDateUntil?: string, 
    endDateFrom?: string, 
    endDateUntil?: string, 
    pageSize?: number, 
    pageIndex?: number, 
    sortBy?: string) => {

    const client = createClient(axios);

    const params = getQueryStringParams({
        partnerId,
        internalUnitId,
        status,
        name,
        email,
        startDateFrom,
        startDateUntil,
        endDateFrom,
        endDateUntil,
        pageSize,
        pageNumber: pageIndex,
        sortBy
    });

    return client.get(endpoint(`api/v1/temporary-accesses?${params}`));
}

export type CreateAccessCommandUnitType = {
    unitName: string,
    internalUnitId: string
}
export type CreateAccessCommandType = {
    partnerId: string, 
    code: string, 
    firstName: string, 
    lastName: string,
    email: string,
    startDateTime: string,
    endDateTime: string,
    units: CreateAccessCommandUnitType[]
}

export const createAccess = (access: CreateAccessCommandType) => {

    const client = createClient(axios);

    return client.post(endpoint(`api/v1/temporary-accesses`), access);
}

export const recreateAccess = (id: string) => {

    const client = createClient(axios);

    return client.put(endpoint(`api/v1/temporary-accesses/${id}`));
}

export const deleteAccess = (id: string) => {

    const client = createClient(axios);

    return client.delete(endpoint(`api/v1/temporary-accesses/${id}`));
}