import React, { useState } from "react";
import { DeleteForever, FileCopy, Refresh, Remove } from "@mui/icons-material";
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, SelectChangeEvent, Snackbar, Tooltip, Typography } from "@mui/material";
import { Card, CardBody, Collapse, Col, Row, Table, Container } from "reactstrap";
import Loader from "../../../../components/Loader/Loader";
import { EasyHubPrimaryColor } from "../../../../variables/colors";
import { IsAdmin } from "../../../../util/role-helpers";
import "./style.css";
import { useSnack } from "../../../../hooks/useSnack";
import { useModal } from "../../../../hooks/useModal";
import SnackbarComponent from "../../../../components/Snackbar/Snackbar";
import { createAccess, CreateAccessCommandType, deleteAccess, recreateAccess } from "../../../../actions/accesses-actions";
import { arrayNotNullAndHasEntries, isNotNullOrEmpty } from "../../../../util/null-helper";
import SortButton from "../../../../components/Headers/SortButton";
import CreateAccessModal from "./CreateAccessModal";
import moment from "moment-timezone";
import { TEMPORARY_ACCESS_STATUS } from "../../../../variables/access-status";
import DeleteConfirmationdDialog from "../../components/dialogs/DeleteConfirmationDialog";

type AccessTableProps = {
  accesses: Array<any>,
  partners: Array<any>,
  createAccessModal: any,
  units: Array<any>,
  pageSize: number, 
  role: string, 
  sortBy: string, 
  isLoading: boolean,
  tableLegend?: string,
  isAdmin: boolean,
  handlePageSizeChange: (e: SelectChangeEvent) => void,
  handleClickSort: (direction: string, column: string) => void,
  handlePartnerChange: (id: string) => void,
  handleRefreshAccesses: () => void
}
const AccessTable = ({
  accesses,
  partners,
  createAccessModal,
  units,
  pageSize,
  role,
  sortBy,
  isLoading,
  tableLegend,
  isAdmin,
  handlePageSizeChange,
  handleClickSort,
  handlePartnerChange,
  handleRefreshAccesses
}: AccessTableProps) => {
  const [openedCollapses] = useState(["collapseOne"]);
  const snack = useSnack();
  const [accessToDelete, setAccessToDelete] = useState("");
  const confirmDeleteAccessDialog = useModal();

  const handleSuccess = (message: string) => {
    snack.setSeverity("success");
    snack.setMessage(message);
    snack.onOpen();
  }

  const handleConfirmCreateAccess = async (access: CreateAccessCommandType) => {
    try{
      await createAccess(access);
      handleSuccess(`Success! Access code will be created shortly, refresh the page to check the last status.`);
      handleRefreshAccesses();
      createAccessModal.onClose();
    }
    catch(err){
      handleCreateAccessError(err.response.data);
      throw err;
    }
  };

  const handleRecreate = async (id: string) => {
    try{
      await recreateAccess(id);
      handleSuccess(`Success! Access code will be re-created shortly, refresh the page to check the last status.`);
      handleRefreshAccesses();
      createAccessModal.onClose();
    }
    catch(err){
      handleCreateAccessError(err.response.data);
      throw err;
    }
  }

  const handleConfirmDeleteAccess = async () => {
    try{
      setAccessToDelete("");
      confirmDeleteAccessDialog.onClose();
      await deleteAccess(accessToDelete);
      handleSuccess(`Success! Access code will be deleted shortly, refresh the page to check the last status.`);
      handleRefreshAccesses();
      createAccessModal.onClose();
    }
    catch(err){
      handleCreateAccessError(err.response.data);
      throw err;
    }
  }
  
  console.log('accesses', accesses);
  const handleClickCreateAccess = (reservation: any) => {
    createAccessModal.onOpen();
  }

  const handleCreateAccessError = (message: string) => {
    snack.setSeverity("error");
    snack.setMessage(message);
    snack.onOpen();
  }

  const handleCloseCreateAccess = () => {
    createAccessModal.onClose();
  }

  const convertToDate = (timeStamp?: number) => {
    return isNotNullOrEmpty(timeStamp)
      ? moment(timeStamp).tz('America/New_York').format("DD/MMM/YYYY HH:mm")
      : '-';
  }

  const handleClickDeleteAccess = (id: string) => {
    setAccessToDelete(id);
    confirmDeleteAccessDialog.onOpen();
  }
  
  const handleAbortDeletion = () => {
    setAccessToDelete("");
    confirmDeleteAccessDialog.onClose();
  }

  return (
    <>
      <div className="accordion">
        <Card className="card-plain">
          <Collapse
            role="tabpanel"
            isOpen={openedCollapses.includes("collapseOne")}
          >
            <CardBody>
              <Container fluid>
                <Row>
                  {isNotNullOrEmpty(tableLegend) && (
                    <Col xl={12} className="mb-3">
                      <Typography
                        color={EasyHubPrimaryColor}
                        fontSize="12px"
                        fontStyle="italic"
                      >
                        {tableLegend}
                      </Typography>
                    </Col>
                  )}
                  <Col xl={12}>
                    {isLoading && (
                      <div
                        className="d-flex justify-content-center"
                        style={{
                          position: "absolute",
                          left: 0,
                          right: 0,
                          top: "40%",
                        }}
                      >
                        <Loader />
                      </div>
                    )}
                    <Table className="align-items-center table-flush" responsive>
                      <thead className="thead-light">
                        <tr>
                          <th scope="col">
                            Status{" "}
                            <SortButton
                              column="Status"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "Status")
                              }
                            />
                          </th>
                          <th scope="col">
                            First Name{" "}
                            <SortButton
                              column="FirstName"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "FirstName")
                              }
                            />
                          </th>
                          <th scope="col">
                            Last Name{" "}
                            <SortButton
                              column="LastName"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "LastName")
                              }
                            />
                          </th>
                          <th scope="col">
                            E-mail{" "}
                            <SortButton
                              column="Email"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "Email")
                              }
                            />
                          </th>
                          <th scope="col">
                            Units
                          </th>
                          <th scope="col">
                            Code{" "}
                            <SortButton
                              column="Code"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "Code")
                              }
                            />
                          </th>
                          <th scope="col">
                            Start Date{" "}
                            <SortButton
                              column="StartDateTimeStamp"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "StartDateTimeStamp")
                              }
                            />
                          </th>
                          <th scope="col">
                            End Date{" "}
                            <SortButton
                              column="EndDateTimeStamp"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "EndDateTimeStamp")
                              }
                            />
                          </th>
                          <th scope="col">
                            Create Date{" "}
                            <SortButton
                              column="CreateDate"
                              selected={sortBy}
                              onClick={(direction) =>
                                handleClickSort(direction, "CreateDate")
                              }
                            />
                          </th>
                          <th scope="col">
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {accesses &&
                          accesses.map((i) => (
                            <tr key={`unit_${i.id}`}>
                              <td>
                                {TEMPORARY_ACCESS_STATUS[i.status]}
                              </td>
                              <td>
                                {i.firstName}
                              </td>
                              <td>
                                {i.lastName}
                              </td>
                              <td>
                                {i.email}
                              </td>
                              <td style={{ textAlign: 'center', color: EasyHubPrimaryColor, fontWeight: 'bold' }}>
                                ({arrayNotNullAndHasEntries(i.units) ? <Tooltip className="hover" title={<Box>
                                  {i.units.map((u) => <Box>
                                    {u.unitName}
                                  </Box>)}
                                </Box>}>
                                  {i.units.length} 
                                </Tooltip>: 0})
                              </td>
                              <td>
                                {i.code}
                              </td>
                              <td>
                                {convertToDate(i.startDateTimeStamp)}
                              </td>
                              <td>
                                {convertToDate(i.endDateTimeStamp)}
                              </td>
                              <td>
                                {convertToDate(i.createDate)}
                              </td>
                              <td scope="row" style={{ textAlign: 'center' }}>
                                <Tooltip title={"Delete"}>
                                  <IconButton onClick={() => handleClickDeleteAccess(i.id)}>
                                    <DeleteForever />
                                  </IconButton>
                                </Tooltip> 
                                {i.status === 3 ? <Tooltip title={"Resend"}>
                                  <IconButton onClick={() => handleRecreate(i.id)}>
                                    <Refresh />
                                  </IconButton>
                                </Tooltip> : null}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                  <Col>
                    <Col xl={{ size: 2, offset: 10 }}>
                      <FormControl sx={{ m: 2, width: "100%" }}>
                        <InputLabel id="page-size-filter">Items per page</InputLabel>
                        <Select
                          labelId="page-size-filter"
                          id="page-size-select"
                          value={pageSize.toString()}
                          label="Page Size"
                          onChange={handlePageSizeChange}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={50}>25</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                          <MenuItem value={200}>200</MenuItem>
                          <MenuItem value={300}>300</MenuItem>
                        </Select>
                      </FormControl>
                    </Col>
                  </Col>
                </Row>
              </Container>
            </CardBody>
          </Collapse>
        </Card>
      <CreateAccessModal 
        open={createAccessModal.isOpen}
        partners={partners}
        units={units}
        isAdmin={isAdmin}
        onClose={handleCloseCreateAccess}
        onCreateAccessCodeError={handleCreateAccessError}
        onCreateAccessCode={handleConfirmCreateAccess}
        onPartnerChange={handlePartnerChange}
      />
      <DeleteConfirmationdDialog 
        isOpen={confirmDeleteAccessDialog.isOpen}
        accessToDeleteId={accessToDelete}
        onClose={handleAbortDeletion}
        message="Are you sure you want to delete the access?"
        onConfirmDelete={handleConfirmDeleteAccess}
      />
      <SnackbarComponent
          isOpen={snack.isOpen}
          severity={snack.severity}
          onClose={snack.onClose}
          message={snack.message}
      />
      </div>
    </>
  );
};

export default AccessTable;
