import moment from "moment-timezone";
import { isNotNullOrEmpty } from "./null-helper";

export const convertTo13DigitsTimeStamp = (timestamp) => {
    return isNotNullOrEmpty(timestamp) ? parseInt(timestamp + '000') : null;
}
export const convertToTimeStamp = (val) => {
    return isNotNullOrEmpty(val) ? moment(val).unix() : null;
}

export const convertToEasterTime = (val) => {
    return isNotNullOrEmpty(val) ? moment(val).tz('America/New_York').unix() : null;;
}