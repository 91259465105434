export const INTEGRATION_TYPES = {
    Mapro: "Mapro",
    Track: "Track",
    Ciirus: "Ciirus"
}

export const INTEGRATION_TYPES_VAL = {
    Track: 0,
    Ciirus: 1,
    Mapro: 2,
    Streamline: 3
}