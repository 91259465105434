/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.easyhub.ai/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.easyhub.ai)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import { Accordion, AccordionSummary, AccordionDetails, Card, Typography } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { CodeBlock, CopyBlock, dracula } from "react-code-blocks";
import { useSearchParams } from "react-router-dom";
import CardsHeader from "../../../components/Headers/CardsHeader";

function Errors() {

    const [searchParams] = useSearchParams();

    const title = searchParams.get('title');
    const details = searchParams.get('details');

    return (
        <>
        <CardsHeader name="Default" parentName="Dashboards" />
        <Container className="mt--6" fluid>
            <Row>
                <Col>
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="settings-content"
                            id="settings-header"
                        >
                                <span className="h2 mb-3">Error Details</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Card>
                                <Typography>
                                    {title}
                                </Typography>
                                <CopyBlock
                                    text={details}
                                    language={'C#'}
                                    showLineNumbers={false}
                                    theme={dracula}
                                    wrapLongLines={true}
                                    codeBlock
                                />
                            </Card>
                        </AccordionDetails>
                    </Accordion>
                </Col>
            </Row>
            <Row>
            </Row>
        </Container>
        </>
    );
}

export default Errors;
