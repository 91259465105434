import axios from "axios"
import { createClient } from "../util/axios-helpers";
import { getQueryStringParams } from "../util/http-helpers";
import { endpoint } from "../util/http-helpers";

export const searchForReservations = (
    partnerId: string, 
    externalReservationId: string, 
    tuyaUnitId: string, 
    roomNo: string,
    status: string, 
    pageSize: number, 
    pageIndex: number, 
    checkinDateFrom: number, 
    checkinDateUntil: number, 
    checkoutDateFrom: number, 
    checkoutDateUntil: number, 
    deviceId: string,
    lockerPassword: string,
    sortBy: string
) => {
    const client = createClient(axios);
    const params = getQueryStringParams({
        partnerId,
        externalReservationId,
        tuyaUnitId,
        roomNo,
        status,
        pageSize,
        pageNumber: pageIndex,
        checkinDateFrom,
        checkinDateUntil, 
        checkoutDateFrom, 
        checkoutDateUntil,
        deviceId,
        lockerPassword,
        sortBy
    });

    return client.get(endpoint(`api/v1/reservations?${params}`));
}


export const getReservation = (externalId: string) => {
    const client = createClient(axios);

    const params = getQueryStringParams({
        externalId
    });

    return client.get(endpoint(`api/v1/reservation?${params}`));
}

export const getReservationById = (reservationId: string) => {
    const client = createClient(axios);

    const params = getQueryStringParams({
        id: reservationId
    });

    return client.get(endpoint(`api/v1/reservation?${params}`));
}
export type ForceReservationUpdateCommand = {
    reservationId: string,
    doorLockCode: string,
    arrivalDate: string,
    departureDate: string
}

export const forceReservationCodeUpdate = (updateComand: ForceReservationUpdateCommand) => {
    const client = createClient(axios);

    return client.post(endpoint(`api/v1/reservation/{reservationId}/door-lock`), updateComand);
}