import React, { useEffect, useState } from "react";
import { Box, Button, FormControl, IconButton, InputAdornment, Modal, TextField, Typography } from "@mui/material"
import { Col, Container, Row } from "reactstrap";
import { EasyHubPrimaryColor } from "../../../../variables/colors";
import PartnerSelect from "../PartnerSelect";
import DateField from "../../../../components/Date/DateField";
import { arrayNotNullAndHasEntries, isNotNull, isNotNullOrEmpty } from "../../../../util/null-helper";
import moment from "moment";
import { ForceReservationUpdateCommand } from "../../../../actions/reservation-actions";
import { Refresh } from "@mui/icons-material";
import { randomSixDigitsNumber } from "../../../../util/numbers-helper";

type UpdatePasswordModalProps = {
    open: boolean,
    partners: Array<any>,
    reservation: any,
    onClose: () => void,
    onUpdateReservation: (updateCommand: ForceReservationUpdateCommand) => void,
    onUpdateReservationCodeError: (message: string) => void
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function UpdatePasswordModal({ open, partners, reservation, onClose, onUpdateReservation, onUpdateReservationCodeError }: UpdatePasswordModalProps){

    const [partnerId, setPartnerId] = useState<string>(null);
    const [doorLockCode, setDoorLockCode] = useState("");
    const [identifierId, setIdentifierId] = useState("");
    const [arrivalDate, setArrivalDate] = useState(null);
    const [reservationId, setReservationId] = useState(null);
    const [departureDate, setDepartureDate] = useState(null);

    const handleClose = () => {
        setIdentifierId("");
        setArrivalDate(null);
        setDepartureDate(null);
        setPartnerId(null);
        setDoorLockCode("");
        setReservationId(null);
        onClose();
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            onUpdateReservation({
                reservationId,
                doorLockCode,
                arrivalDate,
                departureDate
            });
        } catch(err){
            onUpdateReservationCodeError(err.response.data);
        }
    }
    
    const getPartnerName = (partnerId: string) => {
        let result = "-";

        if(arrayNotNullAndHasEntries(partners) && isNotNullOrEmpty(partnerId)) {
            const filtered = partners.filter(i => i.id === partnerId);
            result = arrayNotNullAndHasEntries(filtered) ? filtered[0].name : '-';
        }

        return result;
    }

    useEffect(() => {
        if(isNotNull(reservation)){
            setPartnerId(reservation.partnerId);
            setArrivalDate(isNotNullOrEmpty(reservation.arrivalTimestamp) ? moment(reservation.arrivalTimestamp): null);
            setDepartureDate(isNotNullOrEmpty(reservation.departureTimestamp) ? moment(reservation.departureTimestamp) : null);
            setIdentifierId(reservation.externalReservationId);
            setDoorLockCode(reservation.lockerPassword);
            setReservationId(reservation.id);
        }
    }, [reservation]);

    return open === true ? <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-update-lock-title"
        aria-describedby="modal-update-lock-description"
        >
        <Box sx={style}>
            <form onSubmit={handleSubmit}>
                <Container>
                    <Row>
                        <Col lg={12} style={{ color: EasyHubPrimaryColor, marginLeft: '10px' }}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Force Reservation Update
                            </Typography>
                        </Col>
                        <Col lg={6} style={{ marginTop: "30px"}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="update_reservation_partner"
                                    label="Client"
                                    value={getPartnerName(partnerId)}
                                    disabled
                                    required
                                />
                            </FormControl>
                        </Col> 
                        <Col lg={6} style={{ marginTop: '30px'}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="update_reservation_external"
                                    label="External Reservation Id"
                                    onChange={(e) => setIdentifierId(e.target.value)}
                                    value={identifierId}
                                    disabled
                                    required
                                />
                            </FormControl>
                        </Col>
                        <Col lg={6} style={{ marginTop: '20px'}}>
                            <FormControl fullWidth>
                                <DateField
                                    label="Arrival Date"
                                    value={arrivalDate}
                                    onChange={(newValue) => setArrivalDate(newValue)}
                                />
                            </FormControl>
                        </Col>
                        <Col lg={6} style={{ marginTop: '20px'}}>
                            <FormControl fullWidth>
                                <DateField
                                    label="Departure Date"
                                    value={departureDate}
                                    onChange={(newValue) => setDepartureDate(newValue)}
                                />
                            </FormControl>
                        </Col>
                        <Col lg={6} style={{ marginTop: '30px'}}>
                            <FormControl fullWidth>
                                <TextField
                                    id="update_reservation_code"
                                    label="Door Lock Code"
                                    onChange={(e) => setDoorLockCode(e.target.value)}
                                    value={doorLockCode}
                                    required
                                    slotProps={{
                                        input: {
                                          endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setDoorLockCode(randomSixDigitsNumber().toString())}>
                                                    <Refresh />
                                                </IconButton>
                                            </InputAdornment>
                                          ),
                                        },
                                      }}
                                />
                            </FormControl>
                        </Col>
                        <Col lg={12} style={{ textAlign: "right", marginTop: "30px"}}>
                            <Button variant="outlined" onClick={handleClose} style={{ marginRight: "10px"}}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="contained">
                                Update
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </form>
           
        </Box>
    </Modal> : <></>;
}